<template>
  <div class="box-404">
      <h3 class="title">页面飞离地球了...</h3>
      <div class="res-text">
        <div class="li-box">
          <ul>
            <li>管理员没有开放权限访问</li>
            <li>您还未登陆或者注册</li>
            <li class="pt20">
              <el-button type="primary" class="w200 btn" @click="jump">回首页</el-button>
            </li>
          </ul>
        </div>
      </div>
  </div>
</template>
<script>
export default {
  methods: {
    jump() {
      this.$router.push({
        name: 'home',
      })
    },
  },
}
</script>
<style scoped>
.box-404 {
  height: 480px;
  width: 480px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -240px;
  margin-left: -200px;
  background: url('~@/assets/images/no-menu.png') 50% 0 no-repeat;
  background-size: 50% auto;
  text-align: center;
  padding-top: 200px;
}
.title {
  font-size: 28px;
  color: #409EFF;
  line-height: 50px;
}
.res-text {
  font-size: 16px;
  margin-top: 20px;
  line-height: 2;
  color: #999999;
  margin: 0 auto;
}
</style>
